<template>
  <Dialog>
    <div class="px-md pb-md flex flex-col max-h-[92vh]">
      <div class="flex justify-center mb-sm mt-md">
        <Headline
          headline="user.account.verficationFile.deleteDialog.headline"
          level="h4"
        />
      </div>
      <div class="h-full overflow-auto">
        <div v-if="errors.length" class="mb-md">
          <UnknownError />
        </div>
        <div class="text-center">
          {{ t('user.account.verficationFile.deleteDialog.text') }}
        </div>
        <BasicButton
          classes="w-full px-sm md:px-0 pt-md"
          :label="t('user.account.verficationFile.deleteDialog.delete')"
          :type="EButtonType.SUBMIT"
          :btn-style="EButtonStyle.CTA"
          :loading-animation="isLoading"
          bg-color="var(--vdv-btn-base)"
          :disabled="isLoading"
          full-width
          @click="deleteFile()"
        ></BasicButton>
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-vdv-base mt-md"
          @click="dialog.cancel()"
        >
          <span class="anim__underline-link vdv-anim-link">
            {{ t('user.account.verficationFile.deleteDialog.cancel') }}
          </span>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import { useDeleteVerificationFile } from '../useDeleteVerificationFile';
import UnknownError from '@/components/formFields/errors/unknown/unknownError.vue';
import { Headline, BasicButton } from '@/complib';
const { t } = useTrans();

const { errors, isLoading, deleteFile, dialog } = useDeleteVerificationFile();
</script>
